import React, { Fragment, useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';
import { TextField, Grid, FormControl, Select, MenuItem, Box, useTheme, Stack, FormControlLabel, Checkbox, Typography, Collapse, FormGroup, FormLabel, Divider } from '@mui/material';

import SectionContainer from '../global/SectionContainer';
import { BINTU_ORGA } from '../../utils/helper/ls-vars';
import { LIVE_PROCESSING } from '../../utils/helper/nanostream-cloud';
import { AddTask, CircleOutlined } from '@mui/icons-material';

export default function AddLiveProcessing(props) {
    const theme = useTheme();
    const { updateLiveProcessing, hideTitle, prevOptions, profiles, hasTranscoding } = props;
    const orga = sessionStorage.getItem(BINTU_ORGA) ? JSON.parse(sessionStorage.getItem(BINTU_ORGA)) : false;
    const allowedOperations = orga?.allowedOpcodes ? orga.allowedOpcodes : [];

    const [options, setOptions] = useState([]);
    const [checked, setChecked] = useState([]);
    const [selectedStreams, setSelectedStreams] = useState([]);

    const handleCheckProcess = (id) => () => {
        setChecked(prevChecked =>
            prevChecked.includes(id)
                ? prevChecked.filter(e => e !== id)
                : [...prevChecked, id]
        );
    };

    const handleUpdate = (opcodeId, dataId) => (e) => {
        const value = e.target.value.trim();
        setOptions(prev =>
            prev.map(opt =>
                opt.id === opcodeId
                    ? { ...opt, [dataId]: value }
                    : opt
            )
        );
    };

    const toggleStreamSelection = (opcodeId, streamIndex) => () => {
        setSelectedStreams((prev) => {
            const streams = prev[opcodeId] || [];
            const updatedStreams = streams.includes(streamIndex)
                ? streams.filter(s => s !== streamIndex)
                : [...streams, streamIndex];
            return { ...prev, [opcodeId]: updatedStreams };
        });
    };

    const handleBlur = (opcodeId, dataId, min, max, defaultValue) => (e) => {
        const value = e.target.value.trim();
        const parsedValue = parseInt(value, 10);

        const isValid = !isNaN(parsedValue) && parsedValue >= min && (max === undefined || parsedValue <= max);

        setOptions(prevOptions =>
            prevOptions.map(opt =>
                opt.id === opcodeId
                    ? { ...opt, [dataId]: isValid ? parsedValue : defaultValue }
                    : opt
            )
        );
    };

    const createOpcodes = () => {
        const streamOpcodes = {};

        Object.keys(selectedStreams).forEach((opcodeId) => {
            if (!checked.includes(opcodeId)) return;

            const option = options.find(opt => opt.id === opcodeId);
            const streamIds = selectedStreams[opcodeId];

            streamIds.forEach((streamId) => {
                if (!streamOpcodes[streamId]) {
                    streamOpcodes[streamId] = [];
                }
                streamOpcodes[streamId].push({ ...option });
            });
        });

        return Object.keys(streamOpcodes).map(streamIndex => ({
            streamIndex, opcodes: streamOpcodes[streamIndex]
        }));
    };

    useEffect(() => {
        if (options.length === allowedOperations.length) return;

        let assembledOperations;

        if (prevOptions) {
            setChecked(prevOptions.map(opt => opt.id));
            assembledOperations = prevOptions;
        }
        else {
            assembledOperations = allowedOperations.map((a, i) => {
                const operation = LIVE_PROCESSING[a];
                if (operation) {
                    const assembledOperation = { id: operation.id };

                    operation.data.forEach(({ id, limits: { default: defaultValue } }) => {
                        assembledOperation[id] = defaultValue;
                    });

                    return assembledOperation;
                }
            })
        }

        setOptions(assembledOperations);
    }, [allowedOperations, prevOptions]);

    useEffect(() => {
        const checkedProcesses = createOpcodes();
        updateLiveProcessing(checkedProcesses);
    }, [options, checked, selectedStreams]);

    return (
        <SectionContainer noMargin title={!hideTitle && "Set live processing"
        }>
            <Stack direction="row" useFlexGap spacing={1} flexWrap={"wrap"}>
                {
                    allowedOperations?.map((o) => {
                        let opcode = LIVE_PROCESSING[o];
                        let isChecked = checked.includes(opcode?.id);

                        return (
                            <Box key={opcode?.id} sx={{
                                width: 'auto', mt: 1, py: 1, px: 2, border: 1, // width: hideTitle && '100%',
                                borderColor: isChecked ? theme.palette.primary.main : theme.palette.grey[200], borderRadius: 2
                            }}>
                                <FormControl sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size="small"
                                                checked={isChecked}
                                                icon={<CircleOutlined />}
                                                checkedIcon={<AddTask />}
                                                onChange={handleCheckProcess(opcode.id)}
                                            />
                                        }
                                        label={
                                            <Typography variant="body1" color={isChecked ? "primary" : "inherit"}>
                                                {opcode?.title}
                                            </Typography>
                                        }
                                    />
                                    <Collapse in={isChecked}>
                                        {
                                            opcode && opcode.data.map((d, i) => {
                                                let option = options.find(opt => opt.id === opcode?.id);
                                                let min = d.limits.min;
                                                let max = d.limits?.max;
                                                let def = d.limits.default;

                                                return (
                                                    <Box key={`${opcode?.title}-${i}`} sx={{ mb: .5 }}>
                                                        <TextField
                                                            // sx={{ textTransform: 'capitalize' }}
                                                            variant="standard"
                                                            disabled={!isChecked}
                                                            label={`${d.id} (s)`}
                                                            value={option ? option[d.id] : ""}
                                                            helperText={`Min: ${min}${max ? ` | Max: ${max}` : ""}`}
                                                            onChange={handleUpdate(opcode.id, d.id)}
                                                            onBlur={handleBlur(opcode.id, d.id, min, max, def)}
                                                        />
                                                    </Box>
                                                )
                                            })
                                        }
                                        <Divider sx={{ my: 1 }} />
                                        <FormGroup>
                                            <FormLabel sx={{ color: theme.palette.primary.main }}>Assign to <i>n</i> streams</FormLabel>
                                            {
                                                [...Array(profiles.length + 1)].map((p, i) => {
                                                    if (!hasTranscoding && i > 0) return;
                                                    return (
                                                        <FormControlLabel
                                                            key={`${i}-assign-stream`}
                                                            control={
                                                                <Checkbox
                                                                    size="small"
                                                                    checkedIcon={<AddTask />}
                                                                    onChange={toggleStreamSelection(opcode.id, i)}
                                                                />
                                                            }
                                                            label={i === 0 ? "Passthrough" : `${i}. Transcoding Profile`}
                                                        />
                                                    )
                                                })
                                            }
                                        </FormGroup>
                                    </Collapse>
                                </FormControl>
                            </Box>
                        )
                    })
                }
            </Stack>
        </SectionContainer >
    )
}
