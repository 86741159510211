import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import * as methods from '../utils/helper/methods';

import { makeStyles } from '@mui/styles';
import { Collapse, Button, Grid, Tooltip, Divider, Stack, Icon, Typography, Stepper, Step, StepLabel, StepContent, StepIcon, useTheme, FormControlLabel, Checkbox } from '@mui/material';
import { AddCircleOutline, AddCircleRounded, AddOutlined, AddTask, AppShortcut, CircleOutlined, CopyAll, Crop, FiberNew, LocalOffer, LocalOfferOutlined, Loupe, PermMedia, PermMediaOutlined, RemoveOutlined, TimelapseOutlined, TimerOutlined } from '@mui/icons-material';

import permissionConfig from '../utils/permissions/permission_config';
import { usePermission } from '../utils/helper/hooks';
import { BINTU_ORGA } from '../utils/helper/ls-vars';
import { createNewStream, setLiveProcessing } from '../utils/bintu/api-requests';

import SectionHeader from '../components/global/SectionHeader';
import AddTags from '../components/create/AddTags';
import AddTranscoding from '../components/create/AddTranscoding';
import AddMetadata from '../components/create/AddMetadata';
import SnackbarMessage from '../components/global/SnackbarMessage';
import AddThumbnail from '../components/create/AddThumbnail';
import NoAccess from '../components/global/NoAccess';
import { NEW_STREAM } from '../utils/helper/link-config';
import AddLiveProcessing from '../components/create/AddLiveProcessing';



export default function CreateStream(props) {
    const theme = useTheme();
    const navigate = useNavigate();

    const [showError, setShowError] = useState(false);
    const [error, setError] = useState({});

    const orga = sessionStorage.getItem(BINTU_ORGA) ? JSON.parse(sessionStorage.getItem(BINTU_ORGA)) : false;
    const canCreateStream = usePermission(permissionConfig.permissionMasks.stream.createStream);
    const canFetchTranscodingProfiles = usePermission(permissionConfig.permissionMasks.transcoding_profiles.getTranscodingProfiles);
    const canSetTimecode = usePermission(permissionConfig.permissionMasks.streamWithId.setTimecode);
    const canSetOpcode = usePermission(permissionConfig.permissionMasks.streamWithId.setOpcode);

    const [tags, setTags] = useState([]);
    const [timecode, setTimecode] = useState(0);
    const [transcodes, setTranscodes] = useState([]);
    const [opcodes, setOpcodes] = useState([]);

    const [options, setOptions] = useState({
        transcoding: { enabled: orga?.transcoding && canFetchTranscodingProfiles, open: orga?.transcoding && canFetchTranscodingProfiles },
        processing: { enabled: canSetOpcode && true, open: false },
        timecode: { enabled: orga?.metadata && canSetTimecode, open: false },
    });

    document.title = "Create new Stream | nanoStream Cloud Dashboard";

    const handleCreateNewStream = async () => {
        if (!canCreateStream) return;
        let hasTranscodes = options.transcoding.open
        let hasProcessing = opcodes.length > 0;
        let hasTimecode = options.timecode.open;
        let transcodeList = hasTranscodes ? transcodes : [];

        let streamData = {
            ...(tags.length > 0 && { tags }),
            ...(hasTranscodes && { transcodes: transcodeList })
        }


        createNewStream(streamData)
            .then((response) => {
                if (response.success) {
                    let stream = response.data;

                    console.log(opcodes)

                    if (hasProcessing) {

                        let streams = stream.playout.rtmp;

                        streams.forEach((rtmpStream, i) => {
                            let streamname = rtmpStream.streamname;
                            const matchingOpcodes = opcodes.find(op => op.streamIndex === String(i));
                            if (matchingOpcodes) {
                                let newOpcodes = {
                                    streamid: streamname,
                                    opcodes: matchingOpcodes.opcodes
                                };

                                setLiveProcessing(newOpcodes)
                                    .then((response) => { console.log(streamname, response) })
                                    .catch((error) => {
                                        if (!error.success) {
                                            setError(error);
                                            setShowError(true);
                                        }
                                    })
                            }
                        })

                    }

                    if (hasTimecode) {
                        let timecodeData = {
                            streamid: stream.id,
                            hasTimecode: options.timecode.open,
                            timecode: timecode
                        };
                        setTimecode(timecodeData)
                            .then((response) => {
                            }).catch((error) => {
                                if (!error.success) {
                                    setError(error);
                                    setShowError(true);
                                }
                            })
                    }
                    navigate(`${NEW_STREAM}/${stream.id}`)
                }
            }).catch(() => {
                if (!error.success) {
                    setError(error);
                    setShowError(true);
                }
            })

    }

    const updateTagList = (list) => { setTags(list); }
    const updateTranscodingProfiles = (profiles) => { setTranscodes(profiles); }
    const updateTimecode = (newTimecode) => { setTimecode(newTimecode) }
    const updateLiveProcessing = (newProcesses) => { setOpcodes(newProcesses) }

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') return;
        setShowError(false);
    }

    const expand = (id) => () => {
        setOptions({
            ...options,
            [id]: { ...options[id], open: !(options[id].open) }
        })
    }

    return (
        <Fragment>
            {
                !canCreateStream
                    ? <NoAccess content="Stream Creation" />
                    :
                    <Grid container>
                        <SnackbarMessage
                            open={showError}
                            close={handleCloseError}
                            type={"error"}
                        >
                            <b>Error: {error.code}</b> {error.message}
                        </SnackbarMessage>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={.5} alignItems={"center"}>
                                <Icon>
                                    <AddCircleRounded color="primary" />
                                </Icon>
                                <SectionHeader title="Create nanoStream" />
                            </Stack>
                            <Divider sx={{ my: 1 }} />
                        </Grid>
                        <Grid>
                            <Stepper orientation="vertical">
                                <Step active={true}>
                                    <StepLabel icon={<LocalOfferOutlined color="primary" />}>
                                        <Typography variant="h6" color="primary" sx={{ fontWeight: 700 }}>
                                            Tag(s)
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Add tag(s) that you can use to identify your stream more easily in the future.
                                        </Typography>
                                    </StepLabel>
                                    <StepContent>
                                        <AddTags updateTagList={updateTagList} />
                                    </StepContent>
                                </Step>

                                <Step active={true}>

                                    <StepLabel icon={<PermMediaOutlined sx={{ color: canFetchTranscodingProfiles ? theme.palette.primary.main : theme.palette.text.disabled }} />}>
                                        <Typography variant={canFetchTranscodingProfiles ? "h6" : "body1"} color={canFetchTranscodingProfiles ? "primary" : "text.disabled"} sx={{ fontWeight: canFetchTranscodingProfiles ? 700 : 500 }}>
                                            Transcoding Profile(s)
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Add transcoding profiles to enable Adaptive Bitrate (ABR) for your stream.
                                        </Typography>
                                    </StepLabel>
                                    <StepContent>
                                        <FormControlLabel
                                            disabled={!canFetchTranscodingProfiles}
                                            control={<Checkbox checked={options.transcoding.open} icon={<CircleOutlined />} checkedIcon={<AddTask />} size="small" />}
                                            label={"Apply to this stream"}
                                            onChange={expand("transcoding")}
                                        />
                                        <Collapse in={canFetchTranscodingProfiles && options.transcoding.open}>
                                            <AddTranscoding
                                                hideHeader
                                                allowed={canFetchTranscodingProfiles}
                                                applyTranscodingProfiles={updateTranscodingProfiles}
                                            />
                                        </Collapse>
                                    </StepContent>
                                </Step>

                                <Step active={true}>
                                    <StepLabel icon={<CopyAll color="primary" />}>
                                        <Typography variant="h6" color="primary" sx={{ fontWeight: 700 }}>
                                            Live Processing
                                        </Typography>
                                    </StepLabel>
                                    <StepContent>
                                        <Collapse in={canSetOpcode}>
                                            <AddLiveProcessing
                                                hideTitle hasTranscoding={options.transcoding.open}
                                                profiles={transcodes}
                                                updateLiveProcessing={updateLiveProcessing}
                                            />
                                        </Collapse>
                                    </StepContent>
                                </Step>
                                <Step active={true}>
                                    <StepLabel icon={<TimerOutlined color="primary" sx={{ color: canSetTimecode ? theme.palette.primary.main : theme.palette.text.disabled }} />}>
                                        <Typography variant={canSetTimecode ? "h6" : "body1"} color={canSetTimecode ? "primary" : "text.disabled"} sx={{ fontWeight: canSetTimecode ? 700 : 500 }}>
                                            Timecode
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            You can set the time injection interval between 500 ms and 3600000 ms (1 hour), recommended: 1000-5000 ms.
                                        </Typography>
                                    </StepLabel>
                                    <StepContent>
                                        <FormControlLabel
                                            disabled={!canSetTimecode}
                                            control={<Checkbox checked={options.timecode.open} icon={<CircleOutlined />} checkedIcon={<AddTask />} size="small" />}
                                            label={"Apply to this stream"}
                                            onChange={expand("timecode")}
                                        />
                                        <Collapse in={options.timecode.open}>
                                            <AddMetadata hideHeader updateTimecode={updateTimecode} />
                                        </Collapse>
                                    </StepContent>
                                </Step>
                                <Step active={true}>
                                    <StepContent>
                                        <Button
                                            disabled={!canCreateStream}
                                            variant="contained"
                                            onClick={handleCreateNewStream}
                                        >
                                            Create new Stream
                                        </Button>
                                    </StepContent>
                                </Step>
                            </Stepper>
                        </Grid>
                    </Grid>
            }
        </Fragment>
    )
}
